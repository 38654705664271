<template>
  <section class="main">
    <TopSlides class="mb-3" />
    <SecondZoneSlides class="mb-5" />
    
    <SaleHits class="mb-4" />

    <TopBanners class="mb-5" />
    
    <SecondTopBanners class="mb-4" />
    
   
    <Novelties />
    <Brands />
  </section>
</template>

<script>
import SaleHits from '@/views/main/components/SaleHits'
import TopSlides from '@/views/main/components/TopSlides'
import Novelties from '@/views/main/components/Novelties'
import TopBanners from '@/views/main/components/TopBanners'
import SecondTopBanners from '@/views/main/components/SecondTopBanners'
import Brands from '@/views/main/components/Brands'
import axios from "@/services/axios"
export default {
  name: 'MainPage',
  components: {
    SaleHits,
    TopSlides,
    Novelties,
    TopBanners,
    SecondTopBanners,
    Brands,
    SecondZoneSlides: () => import('@/views/main/components/SecondZoneSlides'),
  },

  mounted() {
    if(localStorage.getItem('fuid')){
      axios.post("https://api.sau-bol.kz/v1/user/set-fuid",{
      fuid:localStorage.getItem('fuid')
    })
    localStorage.removeItem('fuid')
    }
    const queryString = window.location.search;

    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('fuid')){
      axios.post("https://api.sau-bol.kz/v1/user/set-fuid",{
      fuid:urlParams.get('fuid')
    }).then(()=>{
      //alert('asdasd')
    }).catch(()=>{
      localStorage.setItem('fuid', urlParams.get('fuid'))
    })
    }
    
  },
}
</script>

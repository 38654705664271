<template>
  <div class="top-slides">
    <b-skeleton-wrapper :loading="isSlidesLoading">
      <template #loading>
        <div class="top-slides__item">
          <b-skeleton-img height="260px"></b-skeleton-img>
        </div>
      </template>
      <VueSlickCarousel v-if="slides && slides.length" v-bind="settings">
        <div @mousedown="start()" @mouseup="end(slide.url)" v-for="slide in slides" :key="slide.id"
          class="top-slides__item" :style="{ backgroundImage: `url(${slide.path})` }">
        </div>
      </VueSlickCarousel>
    </b-skeleton-wrapper>

  </div>
</template>

<script>

import VueSlickCarousel from 'vue-slick-carousel'
import Api from '@/services/api'

export default {
  name: 'TopSlides',
  components: {
    VueSlickCarousel,
  },

  data: () => ({
    isSlidesLoading: false,
    settings: {
      dots: false,
      dotsClass: 'slick-dots custom-dot-class',
      edgeFriction: 0.35,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      // autoplay: true,
      // autoplaySpeed: 4000,
      fastClicked: false
    },
  }),

  computed: {
    slides() {
      return this.$store.state.topSlides
    },
  },

  async mounted() {
    if (this.slides.length) return
    try {
      this.isSlidesLoading = true
      const { banners } = await this.wrapAndHandleRequest(() => Api.getTopSlides('zone=1'), false)
      this.$store.commit('SET_TOP_SLIDES', banners)
    } finally {
      this.isSlidesLoading = false
    }
  },

  methods: {
    start() {
      this.fastClicked = true
      setTimeout(() => {
        this.fastClicked = false
      }, 40)
    },
    end(url) {
      if (this.fastClicked) {
        this.redirect(url)
      }
    },
    asdasd() {
      alert('asdasd')
    },
    redirect(url) {

      if (!url) return
      const link = document.querySelector('.invisibleLink')
      link.setAttribute('href', url)
      link.click()
      window.location.path = url
    },
  },
}
</script>
<style>
.VueCarousel-navigation button:nth-child(1) {
  display: block;
  background-image: url(/arleft.svg);
  margin-left: -30px !important;
  position: absolute;
  z-index: 999;
  background-color: red;
}
</style>

<template>
  <div class="sale-hits">
    <div class="block mb-5">
      <div class="block__title mb-4 container">Популярные товары</div>
      <div class="block__body">
        <VueSlickCarousel v-bind="settings" ref="saleHitsCarousel">
          <template v-if="!hitProductsFirstLine || !hitProductsFirstLine.length">
            <ProductCard v-for="n in 8" :key="n" />
          </template>
          <template v-else>
            <ProductCard v-for="product in hitProductsFirstLine" :key="product.id" :product="product" />
          </template>
        </VueSlickCarousel>
        <span @click="moveCarousel('prev')" class="block__prev"><ArrowIcon direction="left" /></span>
        <span @click="moveCarousel('next')" class="block__next"><ArrowIcon direction="right" /></span>
      </div>
    </div>
    <div class="block" v-if="SecondLineEXists">
      <div class="block__title mb-4 container">Новинки {{ SecondLineEXists }}</div>
      <div class="block__body">
        <VueSlickCarousel v-bind="settings" ref="saleHitsCarouselTwo">
          <template v-if="!hitProductsSecondLine || !hitProductsSecondLine.length">
            <ProductCard v-for="n in 8" :key="n" />
          </template>
          <template v-else>
            <ProductCard v-for="product in hitProductsSecondLine" :key="product.id"  :product="product" />
          </template>
        </VueSlickCarousel>
        <span @click="moveSecondCarousel('prev')" class="block__prev"><ArrowIcon direction="left" /></span>
        <span @click="moveSecondCarousel('next')" class="block__next"><ArrowIcon direction="right" /></span>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import ProductCard from '@/components/Product/ProductCard'
import ArrowIcon from '@/components/ArrowIcon'
import Api from '@/services/api'

export default {
  name: 'SaleHits',
  components: {
    VueSlickCarousel,
    ArrowIcon,
    ProductCard,
  },

  data: () => ({
    settings: {
      arrows: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 0,
      swipe: true,
      responsive: [
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            infinite: true,
          },
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }),

  computed: {
    hitProductsFirstLine() {
      const hitProducts = this.$store.state.products.hitProducts
      if (!hitProducts.length) return []
      return hitProducts[0]
    },

    hitProductsSecondLine() {
      const hitProducts = this.$store.state.products.hitProducts
      if (!hitProducts.length) return []
      return hitProducts[1]
    },
    SecondLineEXists() {
      const hitProducts = this.$store.state.products.hitProducts
      console.log(hitProducts)
      console.log('hitProducts')
      if (!hitProducts[1].length) return false
      //let is_false = false
      return true
    },

  },

  async mounted() {
    if (this.hitProductsFirstLine.length || this.hitProductsSecondLine.length) return
    const { top_favorites } = await this.wrapAndHandleRequest(Api.getHitProducts, false)
    this.$store.commit('products/SET_HIT_PRODUCTS', top_favorites)
  },

  methods: {
    moveCarousel(direction) {
      direction === 'next' ? this.$refs.saleHitsCarousel.next() : this.$refs.saleHitsCarousel.prev()
    },

    moveSecondCarousel(direction) {
      direction === 'next' ? this.$refs.saleHitsCarouselTwo.next() : this.$refs.saleHitsCarouselTwo.prev()
    },
  },
}
</script>
